@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  --cursor-color: #ac5dff;
  --cursor-circle-color: #58c3f457;
}

body {
  margin: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  overflow-x: hidden;
  background-color: #ffffffee;
  /* font-synthesis: none; */
  /* text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  /* border-color: #ffffffde; */
  color: #090909;
}