.slidingImgZoomIn {
  animation: zoomIn 8s ease-in-out forwards;
  transition: linear;
}

.slidingImgZoomOut {
  animation: zoomOut 8s ease-in-out forwards;
  transition: linear;
}

@keyframes zoomIn {
  0% {
      transform: scale(1);
  }

  100% {
      transform: scale(1.2);
  }
}

@keyframes zoomOut {
  0% {
      transform: scale(1.2);
  }

  100% {
      transform: scale(1);
  }
}


.marqueeL {
  display: flex;
  overflow: hidden;
  gap: 1.25rem;
  user-select: none;
}

.marqueeR {
  gap: 1.5rem;
  display: flex;
  overflow: hidden;
  user-select: none;
}


.marqueeL__group {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.25rem;
  min-width: 100%;
  animation: scroll-left 30s linear infinite reverse;
}

.marqueeL__group span {
  color: #ac5dff;
  white-space: nowrap;
  text-transform: uppercase;

}

.marqueeR__group {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
  min-width: 100%;
  animation: scroll-right 30s linear infinite reverse;
}

.marqueeR__group span {
  color: white;
  white-space: nowrap;
  text-transform: uppercase;

}

@keyframes scroll-left {
  from {
      transform: translateX(0);
  }

  to {
      transform: translateX(calc(-100% - 2rem));
  }
}

@keyframes scroll-right {
  from {
      transform: translateX(calc(-100% - 2rem));
  }

  to {
      transform: translateX(0);
  }
}
